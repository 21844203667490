<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner"></div>
    <el-card :body-style="{ padding: '0' }" style="width:65%;margin: 20px auto;" class="box-card">
      <partyBuild ref="newslist" requestType='SearchNews'></partyBuild>
    </el-card>
  </div>
</template>

<script>
import partyBuild from "./newsList/partyBuild";
export default {
  name: "searchNews",
  components: {
    partyBuild,
  },
  data() {
    return {
      value: "",
      FindKeyWords: ""
    };
  },
  created() {
    this.value = this.$route.query.value;
    this.FindKeyWords = this.$route.query.FindKeyWords;
    if (this.value == undefined) {
      this.value = 'search';
    }
  },
  mounted() {
    this.$refs.newslist.getload({
			index: this.value.toString(),
			FindKeyWords: this.FindKeyWords
		});
  }
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px;
  background-image: url(/img/lianxibg.png);
  background-size: 100%;
  line-height: 480px;
}

.enterprise {
  .p1 {
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
}

.time h2 {
  color: #ff6600;
  margin: 20px auto 30px auto;
}
.time-line {
  position: relative;
  width: 215px;
  margin: 0 auto;
  margin-top: 84px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
}
.time-line-div > p:nth-child(1) {
  position: absolute;
  left: -200px;
  width: 240px;
  text-align: right;
  .sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  .sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: -90px;
  }
}
.time-line-div > p:nth-child(2) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 10px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(3) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 120px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(4) {
  position: absolute;
  left: 170px;
  padding: 10px;
  font-size: 24px;
  border-radius: 10px;
  width: 225px;
  top: 105px;
  ._sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  ._sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: 200px;
  }
}
.img-dotted {
  position: absolute;
  width: 1px;
  height: 3000px;
  top: 0;
  z-index: 1;
  background: #dddddd;
  left: 107.5px;
  // background:url('/static/images/vue/dotted.png');
}
.time-line-detail > p {
  margin-left: 30px;
  margin-bottom: 10px;
}

/deep/.el-menu-item.is-active {
  background: linear-gradient(180deg, #fff, #e4f4f5 50%);
  span {
    background: linear-gradient(
      180deg,
      #181a7d 0%,
      #30c9cd 71.4%,
      #5bb960 100%
    );
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
/deep/.el-menu-item:hover {
  // background: linear-gradient(180deg, #fff, #e4f4f5 50%);
  background-color: white !important;
  span {
    background: linear-gradient(
      180deg,
      #181a7d 0%,
      #30c9cd 71.4%,
      #5bb960 100%
    );
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
/deep/.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: -3px;
  color: #333333;
}
/deep/.el-timeline {
  padding-left: 150px;
}
</style>
